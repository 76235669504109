import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import PlainHero, { PlainHeroData } from 'components/HeroBanner/PlainHero';
import Layout, { PageType } from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiryWrapper';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { usePageTracking } from 'helpers/usePageTracking';
import useResetReduxState from 'helpers/useResetReduxState';
import { CsMeta } from 'types/contentStack';

export type SessionExpiredPageProps = {
  data: ExpiredSessionPageData;
  pageType: PageType;
};

export type ExpiredSessionPageData = {
  csSessionExpiredPage: {
    meta: CsMeta;
    hero: PlainHeroData;
    blocks: CsBlock[];
  };
};

export const query = graphql`
  fragment SessionExpiredPage on cs__session_expired_page {
    meta {
      meta_title
    }
    ...PlainHeroSessionExpiredPage
    blocks {
      ...IntroBlockSessionExpiredPage
      ...FeaturesBlockSessionExpiredPage
      ...ActionCardsBlockSessionExpiredPage
    }
  }
`;

const SessionExpiredPage: React.FC<SessionExpiredPageProps> = ({ data, pageType }) => {
  const page = data.csSessionExpiredPage;
  const resetReduxState = useResetReduxState();

  useEffect(() => {
    resetReduxState();
  }, [resetReduxState]);

  usePageTracking(page.meta.meta_title);

  return (
    <Layout
      meta={page.meta}
      pageType={pageType}
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
      <PlainHero hero={page.hero} />
      {page.blocks && mapBlocks(page.blocks)}
    </Layout>
  );
};

export default SessionExpiredPage;
