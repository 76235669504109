import { graphql } from 'gatsby';
import React from 'react';
import SessionExpiredPageTemplate, {
  SessionExpiredPageProps,
} from 'templates/SessionExpiredPage';

export const query = graphql`
  query {
    csSessionExpiredPage(page_id: { eq: "self_serve_session_expired_page" }) {
      page_id
      ...SessionExpiredPage
    }
  }
`;

const SessionExpiredPage: React.FC<SessionExpiredPageProps> = ({ data }) => {
  if (!data.csSessionExpiredPage) {
    throw new Error(
      'Expired session page with page_id `self_serve_session_expired_page` not found'
    );
  }

  return <SessionExpiredPageTemplate data={data} pageType="accountArea" />;
};

export default SessionExpiredPage;
