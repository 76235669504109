import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql } from 'gatsby';
import React from 'react';

export type PlainHeroData = {
  heading: string;
  subheading: string | null;
};

type PlainHeroProps = {
  hero: PlainHeroData;
};

export const query = graphql`
  fragment PlainHeroIneligibleQuotePage on cs__ineligible_quote_page {
    hero {
      heading
      subheading
    }
  }
  fragment PlainHeroSessionExpiredPage on cs__session_expired_page {
    hero {
      heading
      subheading
    }
  }
`;

const PlainHero: React.FC<PlainHeroProps> = ({ hero }) => (
  <HeroPlain
    heading={hero.heading}
    subhead={hero.subheading ?? undefined}
    data-cy="PlainHero"
  />
);

export default React.memo(PlainHero);
